import React from 'react';
import PropTypes from 'prop-types';
import {motion} from 'framer-motion';
import Utils from '../util/util';

const Button = ({title, onClickBtn, className, second, disabled}) => {
  const theme = disabled
    ? 'text-disabled-btn border-disabled-btn'
    : second
    ? 'text-white border-white'
    : 'text-primary border-primary';

  return (
    <motion.button
      title={title}
      onClick={onClickBtn}
      className={`
        p-auto border ${theme} ${className}
        active:opacity-80 px-6
        min-w-button-sm h-button-sm text-sm 
        md:h-button md:min-w-button md:text-base
        lg:h-button-lg lg:min-w-button-lg xl:text-lg
      `}
      whileHover={{
        color: disabled ? '#919191' : second ? '#000000' : '#ffffff',
        backgroundColor: disabled
          ? 'rgba(0, 0, 0, 0)'
          : second
          ? '#ffffff'
          : Utils.theme.color.primary,
      }}
      whileTap={{
        color: disabled ? '#919191' : second ? '#000000' : '#ffffff',
        backgroundColor: disabled
          ? 'rgba(0, 0, 0, 0)'
          : second
          ? '#ffffff'
          : Utils.theme.color.primary,
        transition: {
          duration: 0,
        },
      }}
      transition={{
        duration: 0.2,
      }}
      disabled={disabled}
    >
      {title}
    </motion.button>
  );
};

export default Button;

Button.propTypes = {
  title: PropTypes.string,
  onClickBtn: PropTypes.func,
  className: PropTypes.string,
  second: PropTypes.bool,
  disabled: PropTypes.bool,
};
Button.defaultProps = {
  title: 'Button',
  second: false,
  onClickBtn: () => {},
  disabled: false,
};
